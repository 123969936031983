import React from 'react'
import IndexTemplate from '../pagesTemplates/index'
import gatsbyi18Context from '../contexts/gatsby-i18n-context.js'

export default (props) => {
  const { pageContext } = props
  const { langKey: language } = pageContext

  const data = {
    language,
  }

  return (
    <gatsbyi18Context.Provider value={data}>
      <IndexTemplate {...props} />
    </gatsbyi18Context.Provider>
  )
}

export const query = graphql`
  query {
    aboutProject: wordpressPage(slug: {eq: "about-us-en"}) {
      title
      slug
      excerpt
    }

    maps: wordpressPage(slug: {eq: "maps-en"}) {
      title
      slug
      excerpt
    }

    articles: allWordpressPost(
      limit: 2
      filter: {
        polylang_current_lang: {
          eq: "en_US"
        }
      }
    ) {
      nodes {
        type
        title
        date
        excerpt
        slug
        smallImage: featured_media {
          localFile {
            childImageSharp {
              fixed (width: 540, height: 264) {
                src
              }
            }
          }
        }
        author {
          name
        }
      }
    }

    multimedia: allWordpressWpMultimedia(
      limit: 2
      filter: {
        polylang_current_lang: {
          eq: "en_US"
        }
      }

    ) {
      nodes {
        type
        title
        slug
        smallImage: featured_media {
          localFile {
            childImageSharp {
              fixed (width: 540, height: 264) {
                src
              }
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
